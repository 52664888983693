const cueSet1 = [
  {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 0,
          "time": 0,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 960,
          "time": 0.036764687500000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 1920,
          "time": 0.07352937500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 2880,
          "time": 0.11029406250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 3840,
          "time": 0.14705875000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 4800,
          "time": 0.18382343750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 5760,
          "time": 0.22058812500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 6720,
          "time": 0.2573528125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 7680,
          "time": 0.29411750000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 8640,
          "time": 0.33088218750000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 9600,
          "time": 0.36764687500000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 10560,
          "time": 0.40441156250000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 11520,
          "time": 0.44117625000000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000004,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 12480,
          "time": 0.47794093750000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 13440,
          "time": 0.514705625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 14400,
          "time": 0.5514703125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 15360,
          "time": 0.5882350000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 16320,
          "time": 0.6249996875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 17280,
          "time": 0.6617643750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 18240,
          "time": 0.6985290625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 19200,
          "time": 0.7352937500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 20160,
          "time": 0.7720584375000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 21120,
          "time": 0.8088231250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 22080,
          "time": 0.8455878125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 23040,
          "time": 0.8823525000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 24000,
          "time": 0.9191171875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999995,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 24960,
          "time": 0.9558818750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 25920,
          "time": 0.9926465625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 26880,
          "time": 1.02941125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 27840,
          "time": 1.0661759375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 28800,
          "time": 1.102940625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 29760,
          "time": 1.1397053125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 30720,
          "time": 1.1764700000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 31680,
          "time": 1.2132346875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 32640,
          "time": 1.2499993750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 33600,
          "time": 1.2867640625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 34560,
          "time": 1.3235287500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 35520,
          "time": 1.3602934375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 36480,
          "time": 1.397058125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 37440,
          "time": 1.4338228125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 38400,
          "time": 1.4705875000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 39360,
          "time": 1.5073521875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 40320,
          "time": 1.5441168750000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 41280,
          "time": 1.5808815625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 42240,
          "time": 1.6176462500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 43200,
          "time": 1.6544109375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 44160,
          "time": 1.691175625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 45120,
          "time": 1.7279403125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 46080,
          "time": 1.7647050000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 47040,
          "time": 1.8014696875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 48000,
          "time": 1.8382343750000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 48960,
          "time": 1.8749990625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000006,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 49920,
          "time": 1.9117637500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 50880,
          "time": 1.9485284375000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 51840,
          "time": 1.985293125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 52800,
          "time": 2.0220578125000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 53760,
          "time": 2.0588225,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 54720,
          "time": 2.0955871875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 55680,
          "time": 2.1323518750000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 56640,
          "time": 2.1691165625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 57600,
          "time": 2.20588125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 58560,
          "time": 2.2426459375000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 59520,
          "time": 2.279410625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 60480,
          "time": 2.3161753125000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 61440,
          "time": 2.3529400000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 62400,
          "time": 2.3897046875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 63360,
          "time": 2.4264693750000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 64320,
          "time": 2.4632340625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 65280,
          "time": 2.4999987500000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 66240,
          "time": 2.5367634375000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 67200,
          "time": 2.573528125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 68160,
          "time": 2.6102928125000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 69120,
          "time": 2.6470575000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 70080,
          "time": 2.6838221875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 71040,
          "time": 2.7205868750000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 72000,
          "time": 2.7573515625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 72960,
          "time": 2.79411625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 73920,
          "time": 2.8308809375000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 74880,
          "time": 2.867645625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 75840,
          "time": 2.9044103125000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 76800,
          "time": 2.9411750000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 77760,
          "time": 2.9779396875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 78720,
          "time": 3.0147043750000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 79680,
          "time": 3.0514690625000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 80640,
          "time": 3.0882337500000006,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 81600,
          "time": 3.1249984375000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 82560,
          "time": 3.1617631250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 83520,
          "time": 3.1985278125000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 84480,
          "time": 3.2352925000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 85440,
          "time": 3.2720571875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 86400,
          "time": 3.3088218750000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 87360,
          "time": 3.3455865625000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 88320,
          "time": 3.38235125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 89280,
          "time": 3.4191159375000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 90240,
          "time": 3.4558806250000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 91200,
          "time": 3.4926453125000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 92160,
          "time": 3.5294100000000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 93120,
          "time": 3.5661746875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 94080,
          "time": 3.6029393750000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 95040,
          "time": 3.6397040625000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 96000,
          "time": 3.6764687500000006,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 96960,
          "time": 3.7132334375000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 97920,
          "time": 3.7499981250000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 98880,
          "time": 3.7867628125000006,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 99840,
          "time": 3.8235275000000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000028,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 100800,
          "time": 3.8602921875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 101760,
          "time": 3.8970568750000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 102720,
          "time": 3.9338215625000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 103680,
          "time": 3.97058625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 104640,
          "time": 4.0073509375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 105600,
          "time": 4.044115625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 106560,
          "time": 4.080880312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 107520,
          "time": 4.117645,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 108480,
          "time": 4.1544096875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 109440,
          "time": 4.191174375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 110400,
          "time": 4.227939062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 111360,
          "time": 4.264703750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 112320,
          "time": 4.3014684375000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 113280,
          "time": 4.338233125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 114240,
          "time": 4.3749978125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 115200,
          "time": 4.4117625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 116160,
          "time": 4.448527187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 117120,
          "time": 4.485291875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 118080,
          "time": 4.5220565625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 119040,
          "time": 4.55882125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 120000,
          "time": 4.5955859375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 120960,
          "time": 4.632350625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 121920,
          "time": 4.669115312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 122880,
          "time": 4.7058800000000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 123840,
          "time": 4.7426446875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 124800,
          "time": 4.779409375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 125760,
          "time": 4.816174062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 126720,
          "time": 4.852938750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 127680,
          "time": 4.889703437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 128640,
          "time": 4.926468125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 129600,
          "time": 4.9632328125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 130560,
          "time": 4.999997500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 131520,
          "time": 5.036762187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 132480,
          "time": 5.073526875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 133440,
          "time": 5.1102915625000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 134400,
          "time": 5.14705625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 135360,
          "time": 5.1838209375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 136320,
          "time": 5.220585625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 137280,
          "time": 5.257350312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 138240,
          "time": 5.294115000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 139200,
          "time": 5.3308796875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 140160,
          "time": 5.367644375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 141120,
          "time": 5.404409062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 142080,
          "time": 5.441173750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 143040,
          "time": 5.477938437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 144000,
          "time": 5.5147031250000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 144960,
          "time": 5.5514678125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 145920,
          "time": 5.5882325,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 146880,
          "time": 5.624997187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 147840,
          "time": 5.661761875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 148800,
          "time": 5.698526562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 149760,
          "time": 5.73529125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 150720,
          "time": 5.7720559375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 151680,
          "time": 5.808820625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 152640,
          "time": 5.845585312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 153600,
          "time": 5.882350000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 154560,
          "time": 5.9191146875000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 155520,
          "time": 5.955879375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 156480,
          "time": 5.992644062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 157440,
          "time": 6.029408750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 158400,
          "time": 6.066173437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 159360,
          "time": 6.1029381250000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 160320,
          "time": 6.1397028125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 161280,
          "time": 6.176467500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 162240,
          "time": 6.213232187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 163200,
          "time": 6.249996875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 164160,
          "time": 6.286761562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 165120,
          "time": 6.3235262500000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 166080,
          "time": 6.3602909375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 167040,
          "time": 6.397055625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 168000,
          "time": 6.433820312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 168960,
          "time": 6.470585000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 169920,
          "time": 6.5073496875000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 170880,
          "time": 6.544114375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 171840,
          "time": 6.580879062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 172800,
          "time": 6.617643750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 173760,
          "time": 6.654408437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 174720,
          "time": 6.691173125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 175680,
          "time": 6.7279378125000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 176640,
          "time": 6.7647025,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 177600,
          "time": 6.801467187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 178560,
          "time": 6.838231875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 179520,
          "time": 6.874996562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 180480,
          "time": 6.9117612500000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 181440,
          "time": 6.9485259375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 182400,
          "time": 6.985290625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 183360,
          "time": 7.022055312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 184320,
          "time": 7.058820000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 185280,
          "time": 7.095584687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 186240,
          "time": 7.132349375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 187200,
          "time": 7.169114062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 188160,
          "time": 7.205878750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 189120,
          "time": 7.242643437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 190080,
          "time": 7.279408125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 191040,
          "time": 7.3161728125000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 192000,
          "time": 7.352937500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 192960,
          "time": 7.389702187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 193920,
          "time": 7.426466875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 194880,
          "time": 7.463231562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 195840,
          "time": 7.499996250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 196800,
          "time": 7.5367609375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 197760,
          "time": 7.573525625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 198720,
          "time": 7.610290312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 199680,
          "time": 7.647055000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 200640,
          "time": 7.683819687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 201600,
          "time": 7.7205843750000005,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 202560,
          "time": 7.757349062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 203520,
          "time": 7.794113750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 204480,
          "time": 7.830878437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 205440,
          "time": 7.867643125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 206400,
          "time": 7.904407812500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500000726,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 207360,
          "time": 7.9411725,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999895,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 208320,
          "time": 7.977937187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 209280,
          "time": 8.014701875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 210240,
          "time": 8.051466562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 211200,
          "time": 8.088231250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 212160,
          "time": 8.124995937500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 213120,
          "time": 8.161760625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 214080,
          "time": 8.198525312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 215040,
          "time": 8.23529,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 216000,
          "time": 8.2720546875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 216960,
          "time": 8.308819375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 217920,
          "time": 8.3455840625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 218880,
          "time": 8.38234875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 219840,
          "time": 8.4191134375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 220800,
          "time": 8.455878125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 221760,
          "time": 8.492642812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 222720,
          "time": 8.529407500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 223680,
          "time": 8.566172187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 224640,
          "time": 8.602936875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 225600,
          "time": 8.6397015625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 226560,
          "time": 8.67646625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 227520,
          "time": 8.7132309375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 228480,
          "time": 8.749995625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 229440,
          "time": 8.7867603125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 230400,
          "time": 8.823525,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 231360,
          "time": 8.860289687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 232320,
          "time": 8.897054375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 233280,
          "time": 8.933819062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 234240,
          "time": 8.970583750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 235200,
          "time": 9.007348437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 236160,
          "time": 9.044113125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 237120,
          "time": 9.0808778125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 238080,
          "time": 9.1176425,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 239040,
          "time": 9.1544071875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 240000,
          "time": 9.191171875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 240960,
          "time": 9.227936562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 241920,
          "time": 9.264701250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 242880,
          "time": 9.301465937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 243840,
          "time": 9.338230625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 244800,
          "time": 9.374995312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 245760,
          "time": 9.411760000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 246720,
          "time": 9.4485246875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 247680,
          "time": 9.485289375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 248640,
          "time": 9.5220540625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 249600,
          "time": 9.55881875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 250560,
          "time": 9.5955834375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 251520,
          "time": 9.632348125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 252480,
          "time": 9.669112812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 253440,
          "time": 9.705877500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 254400,
          "time": 9.742642187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 255360,
          "time": 9.779406875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 256320,
          "time": 9.816171562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 257280,
          "time": 9.85293625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 258240,
          "time": 9.8897009375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 259200,
          "time": 9.926465625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 260160,
          "time": 9.9632303125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 261120,
          "time": 9.999995000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 262080,
          "time": 10.036759687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 263040,
          "time": 10.073524375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 264000,
          "time": 10.110289062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 264960,
          "time": 10.147053750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 265920,
          "time": 10.183818437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 266880,
          "time": 10.220583125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 267840,
          "time": 10.2573478125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 268800,
          "time": 10.2941125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 269760,
          "time": 10.3308771875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 270720,
          "time": 10.367641875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 271680,
          "time": 10.404406562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 272640,
          "time": 10.441171250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 273600,
          "time": 10.477935937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 274560,
          "time": 10.514700625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 275520,
          "time": 10.551465312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 276480,
          "time": 10.588230000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 277440,
          "time": 10.624994687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 278400,
          "time": 10.661759375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 279360,
          "time": 10.6985240625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 280320,
          "time": 10.73528875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 281280,
          "time": 10.7720534375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 282240,
          "time": 10.808818125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 283200,
          "time": 10.845582812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 284160,
          "time": 10.882347500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 285120,
          "time": 10.919112187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 286080,
          "time": 10.955876875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 287040,
          "time": 10.992641562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 288000,
          "time": 11.029406250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 288960,
          "time": 11.0661709375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 289920,
          "time": 11.102935625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 290880,
          "time": 11.1397003125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 291840,
          "time": 11.176465,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 292800,
          "time": 11.213229687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 293760,
          "time": 11.249994375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 294720,
          "time": 11.286759062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 295680,
          "time": 11.323523750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 296640,
          "time": 11.360288437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 297600,
          "time": 11.397053125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 298560,
          "time": 11.433817812500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 299520,
          "time": 11.4705825,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 300480,
          "time": 11.5073471875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 301440,
          "time": 11.544111875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 302400,
          "time": 11.580876562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 303360,
          "time": 11.617641250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 304320,
          "time": 11.654405937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 305280,
          "time": 11.691170625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 306240,
          "time": 11.727935312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 307200,
          "time": 11.764700000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 308160,
          "time": 11.801464687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 309120,
          "time": 11.838229375000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 310080,
          "time": 11.8749940625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 311040,
          "time": 11.91175875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 312000,
          "time": 11.9485234375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 312960,
          "time": 11.985288125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 313920,
          "time": 12.022052812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 314880,
          "time": 12.058817500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 315840,
          "time": 12.095582187500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 316800,
          "time": 12.132346875000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 317760,
          "time": 12.169111562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 318720,
          "time": 12.205876250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 319680,
          "time": 12.242640937500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 320640,
          "time": 12.279405625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 321600,
          "time": 12.3161703125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 322560,
          "time": 12.352935000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 323520,
          "time": 12.389699687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 324480,
          "time": 12.426464375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 325440,
          "time": 12.463229062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 326400,
          "time": 12.499993750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 327360,
          "time": 12.536758437500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 328320,
          "time": 12.573523125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 329280,
          "time": 12.610287812500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 330240,
          "time": 12.647052500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 331200,
          "time": 12.6838171875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 332160,
          "time": 12.720581875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 333120,
          "time": 12.757346562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 334080,
          "time": 12.794111250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 335040,
          "time": 12.830875937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 336000,
          "time": 12.867640625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 336960,
          "time": 12.904405312500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 337920,
          "time": 12.941170000000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 338880,
          "time": 12.977934687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 339840,
          "time": 13.014699375000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 340800,
          "time": 13.051464062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 341760,
          "time": 13.08822875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 342720,
          "time": 13.1249934375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 343680,
          "time": 13.161758125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 344640,
          "time": 13.198522812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 345600,
          "time": 13.235287500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 346560,
          "time": 13.272052187500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 347520,
          "time": 13.308816875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 348480,
          "time": 13.345581562500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 349440,
          "time": 13.382346250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 350400,
          "time": 13.419110937500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 351360,
          "time": 13.455875625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 352320,
          "time": 13.4926403125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 353280,
          "time": 13.529405,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 354240,
          "time": 13.566169687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 355200,
          "time": 13.602934375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 356160,
          "time": 13.639699062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 357120,
          "time": 13.676463750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 358080,
          "time": 13.713228437500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 359040,
          "time": 13.749993125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 360000,
          "time": 13.786757812500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 360960,
          "time": 13.823522500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 361920,
          "time": 13.8602871875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 362880,
          "time": 13.897051875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 363840,
          "time": 13.933816562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 364800,
          "time": 13.970581250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 365760,
          "time": 14.007345937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 366720,
          "time": 14.044110625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 367680,
          "time": 14.080875312500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 368640,
          "time": 14.117640000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 369600,
          "time": 14.154404687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 370560,
          "time": 14.191169375000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 371520,
          "time": 14.227934062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 372480,
          "time": 14.26469875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 373440,
          "time": 14.3014634375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 374400,
          "time": 14.338228125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 375360,
          "time": 14.374992812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 376320,
          "time": 14.411757500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 377280,
          "time": 14.448522187500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 378240,
          "time": 14.485286875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 379200,
          "time": 14.522051562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 380160,
          "time": 14.558816250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 381120,
          "time": 14.595580937500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 382080,
          "time": 14.632345625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 383040,
          "time": 14.6691103125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 384000,
          "time": 14.705875000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 384960,
          "time": 14.742639687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 385920,
          "time": 14.779404375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 386880,
          "time": 14.816169062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 387840,
          "time": 14.852933750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 388800,
          "time": 14.889698437500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 389760,
          "time": 14.926463125000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 390720,
          "time": 14.963227812500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 391680,
          "time": 14.999992500000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 392640,
          "time": 15.036757187500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 393600,
          "time": 15.073521875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 394560,
          "time": 15.110286562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 395520,
          "time": 15.147051250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 396480,
          "time": 15.183815937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 397440,
          "time": 15.220580625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 398400,
          "time": 15.257345312500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 399360,
          "time": 15.294110000000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 400320,
          "time": 15.330874687500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 401280,
          "time": 15.367639375000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 402240,
          "time": 15.404404062500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 403200,
          "time": 15.441168750000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 404160,
          "time": 15.4779334375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 405120,
          "time": 15.514698125000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 406080,
          "time": 15.551462812500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 407040,
          "time": 15.588227500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 408000,
          "time": 15.624992187500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 408960,
          "time": 15.661756875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 409920,
          "time": 15.698521562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 410880,
          "time": 15.735286250000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 411840,
          "time": 15.772050937500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 412800,
          "time": 15.808815625000001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 413760,
          "time": 15.845580312500001,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.036764687500001614,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 414720,
          "time": 15.882345,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 415680,
          "time": 15.919109687500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 416640,
          "time": 15.955874375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999806,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 417600,
          "time": 15.992639062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 418560,
          "time": 16.02940375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000339,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 419520,
          "time": 16.0661684375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 420480,
          "time": 16.102933125000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 421440,
          "time": 16.139697812500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 422400,
          "time": 16.176462500000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 423360,
          "time": 16.213227187500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 424320,
          "time": 16.249991875000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 425280,
          "time": 16.286756562500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 426240,
          "time": 16.323521250000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 427200,
          "time": 16.360285937500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 428160,
          "time": 16.397050625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 429120,
          "time": 16.433815312500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 430080,
          "time": 16.47058,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 431040,
          "time": 16.5073446875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 432000,
          "time": 16.544109375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 432960,
          "time": 16.5808740625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 433920,
          "time": 16.61763875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 434880,
          "time": 16.6544034375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 435840,
          "time": 16.691168125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 436800,
          "time": 16.7279328125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 437760,
          "time": 16.7646975,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 438720,
          "time": 16.8014621875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000339,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 439680,
          "time": 16.838226875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 440640,
          "time": 16.874991562500004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 441600,
          "time": 16.911756250000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 442560,
          "time": 16.948520937500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 443520,
          "time": 16.985285625000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 444480,
          "time": 17.022050312500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 445440,
          "time": 17.058815000000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 446400,
          "time": 17.095579687500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 447360,
          "time": 17.132344375000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 448320,
          "time": 17.169109062500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 449280,
          "time": 17.205873750000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 450240,
          "time": 17.242638437500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 451200,
          "time": 17.279403125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 452160,
          "time": 17.3161678125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 453120,
          "time": 17.3529325,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 454080,
          "time": 17.3896971875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 455040,
          "time": 17.426461875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 456000,
          "time": 17.4632265625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 456960,
          "time": 17.49999125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 457920,
          "time": 17.5367559375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 458880,
          "time": 17.573520625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 459840,
          "time": 17.6102853125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000339,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 460800,
          "time": 17.64705,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 461760,
          "time": 17.683814687500004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 462720,
          "time": 17.720579375000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 463680,
          "time": 17.757344062500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 464640,
          "time": 17.794108750000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 465600,
          "time": 17.830873437500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 466560,
          "time": 17.867638125000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 467520,
          "time": 17.904402812500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 468480,
          "time": 17.941167500000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 469440,
          "time": 17.977932187500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 470400,
          "time": 18.014696875000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 471360,
          "time": 18.051461562500002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 472320,
          "time": 18.08822625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 473280,
          "time": 18.1249909375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 474240,
          "time": 18.161755625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 475200,
          "time": 18.1985203125,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 476160,
          "time": 18.235285,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 477120,
          "time": 18.2720496875,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 478080,
          "time": 18.308814375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 479040,
          "time": 18.3455790625,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 480000,
          "time": 18.38234375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468750000339,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 480960,
          "time": 18.4191084375,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 481920,
          "time": 18.455873125000004,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 482880,
          "time": 18.492637812500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 483840,
          "time": 18.529402500000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 484800,
          "time": 18.566167187500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 485760,
          "time": 18.602931875000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 486720,
          "time": 18.639696562500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 487680,
          "time": 18.676461250000003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 488640,
          "time": 18.713225937500003,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 489600,
          "time": 18.749990625000002,
          "velocity": 0.7874015748031497
        },
        {
          "duration": 0.03676468749999984,
          "durationTicks": 960,
          "midi": 41,
          "name": "F2",
          "ticks": 490560,
          "time": 18.786755312500002,
          "velocity": 0.7874015748031497
        }
];

export default cueSet1;
